<template>
  <div class="relative rounded-md bg-white shadow p-4 flex items-start space-x-2">
    <input v-if="isRecipientPending" v-model="documentSelected" type="checkbox" @change="$emit('toggle')" class="-ml-12 mr-4 mt-2 h-6 w-6 rounded border-neutral-8 text-indigo-600 focus:ring-indigo-600">

    <div
      v-if="type === 'toSign' || document.recipients.length > 1"
      class="flex flex-col mt-1 space-y-1"
    >
      <RecipientBadge
        v-if="type === 'toSign'"
        show-icon
        :is-pending="isRecipientPending"
        small
      />
      <AllRecipientsBadge
        v-if="document.recipients.length > 1"
        class="select-none"
        status="allRecipients"
        :document="document"
        :are-recipients-pending="areRecipientsPending"
        small
      />
    </div>

    <div class="flex items-center justify-between w-full">
      <div class="text-base leading-snug text-neutral-1 line-clamp-2 break-words font-medium">
          {{ document.name }}
      </div>

      <BaseButton
        :loading="loading"
        :outlined="true"
        :disabled="loading"
        :on-click="showDocument"
      >
        {{ t("document.showDocument") }}
      </BaseButton>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import { useI18n } from "vue-i18n";
import useDocument from "../../composables/Document/useDocument";
import RecipientBadge from "./RecipientBadge";
import AllRecipientsBadge from "./AllRecipientsBadge";
import PDFIcon from "../icons/IconPDF";

export default {
  components: {
    PDFIcon,
    AllRecipientsBadge,
    RecipientBadge,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    recipient: {
      type: Object,
      required: true,
    },
    requestId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    isRecipientPending: {
      type: Boolean,
      required: true,
      default: false,
    },
    areRecipientsPending: {
      type: Boolean,
      required: false,
      default: false,
    },
    documentSelected: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { loading, showDocument } = useDocument(
      props.requestId,
      props.document.id,
      store
    );

    return {
      t,
      loading,
      showDocument,
    };
  },
};
</script>
