<template>
  <div
    class="mb-2 border-t-0 text-neutral-1 border-neutral-1"
    :class="type"
  >
    <div>
      <div class="flex flex-col md:space-x-6 md:flex-row md:items-center justify-between">
        <h3 class="text-3xl font-medium md:text-4xl">
          {{ type === "toSign" ? t("request.toSign") : t("request.toView") }}
        </h3>

        <BaseButton class="my-4 md:my-0 text-center py-2.5 px-3 justify-center font-medium" v-if="docsPending(documents).length" @click="onInitiateSigning">
          {{ selectedDocuments.length ? `${selectedDocuments.length} ${selectedDocuments.length == 1 ? 'Dokument' : 'Dokumente'} unterzeichnen` : 'Alle unterzeichnen' }}
        </BaseButton>
      </div>
      
      <div class="space-x-3">
        <div
          v-if="type === 'toSign'"
          class="flex mt-1.5 "
          :class="docsPending(documents).length ? 'text-yellow-3' : 'text-green-3'"
        >
          <div
            class="px-2 text-base font-medium rounded-lg"
            :class="docsPending(documents).length ? 'bg-yellow-9' : 'bg-green-9'"
          >
            {{ docsToSign(documents).length - docsPending(documents).length }}/{{ docsToSign(documents).length }}
            {{ $t('base.done') }}
          </div>
        </div>

        <div
          v-if="type === 'toSign' && docsPending(documents).length && !!request.deadline_at"
          class="font-medium text-base rounded-lg px-2 bg-primary-10 text-primary-3 flex mt-1.5"
        >
          {{ formattedDeadline }}
        </div>
      </div>

      <p class="text-sm mt-6" v-if="docsPending(documents).length">Wählen Sie die zu unterzeichnenden Dokumente aus oder klicken Sie auf "Alle unterzeichnen".</p>
      
      <div class="mt-2 flex flex-col space-y-4" :class="{'pl-8': docsPending(documents).length > 0}">
        <DocumentCardBatch
          v-for="document in sortedDocuments"
          @toggle="toggleDocument(document)"
          :document-selected="selectedDocuments.includes(document)"
          :key="document.id"
          :document="document"
          :recipient="request.recipient"
          :request-id="request.id"
          :type="type"
          :previous-signing-completed="previousSigningCompleted(document)"
          :is-recipient-pending="
            !isRequestDeclined &&
              !!docsPending(documents) &&
              !!docsPending(documents).find(el => el.id === document.id)
          "
          :are-recipients-pending="
            !isRequestDeclined &&
              !!docsRecipientsPending(documents) &&
              !!docsRecipientsPending(documents).find(el => el.id === document.id)
          "
        />
      </div>
    </div>
  </div>
  <teleport to="body">
    <SelectQesTypeModal
      v-if="showConfirmSignModal"
      @onCancel="showConfirmSignModal = false"
      @onSubmit="onSubmit"
      :selected-documents="selectedDocuments"
    />
  </teleport>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import router from "../../router";
import DocumentCard from "./DocumentCard.vue";
import DocumentCardBatch from "./DocumentCardBatch.vue";
import SelectQesTypeModal from "./SelectQesTypeModal.vue";
import useDocuments from "../../composables/Request/useDocuments";
import { formatRelativeTime } from "../../functions/helpers";

export default {
  components: {
    DocumentCard,
    DocumentCardBatch,
    SelectQesTypeModal,
  },
  props: {
    request: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: "toSign",
    },
    deadline: {
      type: String,
      default: undefined,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const { docsPending, docsToSign, docsRecipientsPending, isRequestDeclined, isDocPending } = useDocuments(
      props.request.recipient.id, props.request
    );

    const selectedDocuments = ref([]);
    const showConfirmSignModal = ref(false);

    /* sort documents array (pendingDocs first) */
    const sortedDocuments = computed(() => {
      let tempDocuments = props.documents;
      return tempDocuments.sort((a, b) => {
        if (isDocPending(a) && !isDocPending(b)) {
          return -1;
        } else if (!isDocPending(a) && isDocPending(b)) {
          return 1;
        } else {
          return 0;
        }
      })
    });

    const formattedDeadline = computed(() => {
      return t("request.deadLine", {
        time: capitalizeFirstLetter(
          formatRelativeTime(props.request.deadline_at, 'de')
        ),
      });
    });

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function onInitiateSigning() {
      if (selectedDocuments.value.length === 0) {
        props.documents.forEach(d => {
          if (isDocPending(d)) {
            toggleDocument(d);
          }
        });
      }
      
      showConfirmSignModal.value = true;
    }

    function previousSigningCompleted(document) {
      if (props.documents.filter(d => d.signing_order == null).length > 0) {
        return true;
      }

      if (document.signing_order === 1) {
        return true;
      }

      const previousSigningOrder = document.signing_order - 1;

      const previousDocuments = props.documents.filter(d => d.signing_order === previousSigningOrder);

      return previousDocuments.filter(d => isDocPending(d)).length === 0;
    }

    function toggleDocument(document) {
      if (selectedDocuments.value.includes(document)) {
        selectedDocuments.value = selectedDocuments.value.filter(d => d.id !== document.id);
      } else {
        selectedDocuments.value.push(document);
      }
    }

    async function onSubmit(isQes) {
      await store.dispatch('request/fetchRequest');
      showConfirmSignModal.value = false;
      goBackOrRedirect();
      store.commit("SET_LOADING", false);
    }

    function goBackOrRedirect(isDeclined = false) {
      let request = store.state.request.request;
      let redirectUrl = isDeclined ? request.redirect_error_url : request.redirect_success_url;

      let docsToSignLeft = useDocuments(
        store.state.request.recipientId,
        request,
      ).docsToSign(request.documents, true).length > 0;

      if (docsToSignLeft) {
        return document.location.reload();
      }

      if (redirectUrl && !docsToSignLeft) {
        console.log("redirect externaly");
        router.push({
          name: 'Redirect',
          query: {
            mode: isDeclined ? 'declined' : 'success',
            to: redirectUrl
          }
        })
      } else {
        window.parent.postMessage({
          type: isDeclined ? 'REQUEST_DECLINED' : 'REQUEST_COMPLETED'
        }, '*');

        router.back();
      }
    }

    return {
      t,
      isRequestDeclined,
      docsPending,
      docsToSign,
      docsRecipientsPending,
      formattedDeadline,
      sortedDocuments,
      previousSigningCompleted,
      toggleDocument,
      selectedDocuments,
      showConfirmSignModal,
      onInitiateSigning,
      onSubmit,
    };
  },
};
</script>
