<template>
    <input class="w-full" type="tel" ref="input" v-model="phonenumber" />
</template>

<script>
    import { onMounted, ref, watch, defineEmits } from "vue";
    import intlTelInput from 'intl-tel-input';
    import 'intl-tel-input/build/css/intlTelInput.css';
    import countriesDe from "i18n-iso-countries/langs/de.json"

    export default {
        props: {
            defaultCountryCode: {
                type: String,
                default: "AT"
            }
        },
        emits: ['input'],
        setup(props, { emit }) {
            let germanCountries = [];

            Object.keys(countriesDe.countries).forEach(code => {
                germanCountries[code.toLowerCase()] = countriesDe.countries[code];
            });

            let countryData = window.intlTelInputGlobals.getCountryData();
            Object.keys(countryData).forEach(key => {
                countryData[key].name = germanCountries[countryData[key].iso2];
            });

            const input = ref(null)
            const phonenumber = ref(null);

            onMounted(() => {
                const intl = intlTelInput(input.value, {
                    // todo: update to a local version e.g. build/js/utils.js
                    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js',
                    initialCountry: props.defaultCountryCode,
                    preferredCountries: ['at', 'de', 'ch'],
                    autoPlaceholder: 'aggressive',
                });

                watch(phonenumber, () => {
                    emit('input', intl.isValidNumber() ? intl.getNumber() : null);
                })
            });

            return {
                input,
                phonenumber,
            };
        },
    };
</script>

<style>
    .iti--allow-dropdown input {
        @apply bg-white ring-0 border md:border-2 border-neutral-9 py-1 pl-12 placeholder-neutral-7 text-lg appearance-none focus:border-primary-6 rounded-md !important;
    }

    .iti--allow-dropdown input.has-error {
        @apply border-yellow-6 !important;
    }

    .iti__country-list {
        @apply bg-white border border-neutral-9 shadow mt-1 rounded-md !important;
    }

    .iti__flag-container {
        @apply pl-1 !important;
    }
</style>