import Echo from "laravel-echo";
import pusherJs from "pusher-js";

export default function setupEcho(requestId, token) {
  window.Pusher = pusherJs;
  window.Echo = new Echo(
    // wsHost: window.location.hostname,
    {
      wsHost: process.env.VUE_APP_ECHO_HOST,
      broadcaster: "pusher",
      key: process.env.VUE_APP_ECHO_KEY,
      disableStats: true,
      encrypted: true,
      wsPort: process.env.VUE_APP_ECHO_PORT,
      wssPort: process.env.VUE_APP_ECHO_PORT,
      enabledTransports: ["ws", "wss"],
      authEndpoint: `${process.env.VUE_APP_ECHO_AUTH_ENDPOINT}/requests/${requestId}/socket-auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-Requested-With": "XMLHttpRequest",
          Accept: "application/json",
        },
      },
    }
  );
}
