<template>
  <FadeTransition>
    <div
      :class="classObj"
      v-bind="$attrs"
    >
      <div class="flex-grow">
        <slot />
      </div>

      <div
        v-if="onClose"
        class="pl-2 md:p-0"
        @click="onClose()"
      >
        <BaseIcon
          :size="isSmallScreen ? '24' : '30'"
          class="cursor-pointer"
        >
          <CloseIcon />
        </BaseIcon>
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import { inject } from "vue";
import FadeTransition from "./Transitions/FadeTransition";
import CloseIcon from "../icons/IconClose";
import useBreakPoints from "../../composables/LayoutAndStyle/useBreakPoints";

export default {
  components: {
    CloseIcon,
    FadeTransition,
  },
  props: {
    onClose: {
      type: Function,
      default: undefined,
    }
  },
  setup() {
    const { isSmallScreen } = useBreakPoints();
    const options = inject("alertBoxOptions", {
      position: {
        lgScreen: "top",
        smScreen: "bottom",
      }
    });

    const { position } = options;
    let classObj = "flex fixed z-100 px-3 py-4 md:py-3 w-full text-center shadow-sm left-0 right-0";
    classObj += position.smScreen === "bottom" ? " bottom-0" : " top-0";
    classObj += position.lgScreen === "top" ? " md:top-0 md:bottom-auto" : " md:top-auto md:bottom-0";

    return {
      classObj,
      isSmallScreen,
    }
  }
}
</script>