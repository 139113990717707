const plugin = require('tailwindcss/plugin');

const defaultTheme = require("tailwindcss/defaultTheme");
const colors = {
    transparent: "transparent",
    current: "currentColor",

    black: "#000",
    white: "#fff",
    purple: "#4C51BF",

    primary: {
      1: "#0C008C",
      2: "#1D0EBE",
      3: "#3525E6",
      4: "#4D3DF7",
      5: "#5D55FA",
      6: "#7069FA",
      7: "#8888FC",
      8: "#A2A5FC",
      9: "#C4C6FF",
      10: "#E6E6FF",
    },
    neutral: {
      1: "#1F2933",
      2: "#323F4B",
      3: "#3E4C59",
      4: "#52606D",
      5: "#616E7C",
      6: "#7B8794",
      7: "#9AA5B1",
      8: "#CBD2D9",
      9: "#E4E7EB",
      10: "#F5F7FA",
    },
    blue: {
      1: "#035388",
      2: "#0B69A3",
      3: "#127FBF",
      4: "#1992D4",
      5: "#2BB0ED",
      6: "#40C3F7",
      7: "#5ED0FA",
      8: "#81DEFD",
      9: "#B3ECFF",
      10: "#E3F8FF",
    },
    green: {
      1: "#014D40",
      2: "#0C6B58",
      3: "#147D64",
      4: "#199473",
      5: "#27AB83",
      6: "#3EBD93",
      7: "#65D6AD",
      8: "#8EEDC7",
      9: "#C6F7E2",
      10: "#EFFCF6",
    },
    yellow: {
      1: "#8D2B0B",
      2: "#B44D12",
      3: "#CB6E17",
      4: "#DE911D",
      5: "#F0B429",
      6: "#F7C948",
      7: "#FADB5F",
      8: "#FCE588",
      9: "#FFF3C4",
      10: "#FFFBEA",
    },
    red: {
      1: "#610316",
      2: "#8A041A",
      3: "#AB091E",
      4: "#CF1124",
      5: "#E12D39",
      6: "#EF4E4E",
      7: "#F86A6A",
      8: "#FF9B9B",
      9: "#FFBDBD",
      10: "#FFE3E3",
    },
  };


module.exports = {
  purge: ["./public/index.html", "./src/**/*.{vue,js}"],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      fontFamily: {
        sans: ["Nunito", ...defaultTheme.fontFamily.sans],
      },
      width: {
        "fit-content": "fit-content",
      },
      zIndex: {
        '100': '100',
      },
      spacing: {
        18: "4.5rem",
        "[-100px]": "-100px",
        "[800px]": "800px",
      },
      maxWidth: {
        "[800px]": "800px",
      },
      boxShadow: {
        'top': "0px 3px 8px rgba(0, 0, 0, 0.24)",
        'checkbox-field-primary': `0 0 0 0.1rem ${colors.primary[9]}`,
        'checkbox-field-yellow': `0 0 0 0.1rem ${colors.yellow[9]}`,
        'checkbox-field-green': `0 0 0 0.1rem ${colors.green[9]}`,
        'checkbox-field-neutral': `0 0 0 0.1rem ${colors.neutral[9]}`,
      },
    },

    fontWeight: {
      normal: 400,
      medium: 600,
      bold: 700,
      black: 800,
    },
    colors: {
      ...colors
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      'hover-hover': {'raw': '(hover: hover)'},
      'landscape': {'raw': '(orientation: landscape) and (max-width:800px)'},
    },
  },
  variants: {
    extend: {
      cursor: ['disabled'],
      filter: ['hover'],
      textAlign: ['direction'],
      padding: ['direction'],
    }
  },
  plugins: [
      require("@tailwindcss/forms"),
      require("@tailwindcss/line-clamp"),
      plugin(function({ addVariant, e }) {
          addVariant('direction', ({ container, separator }) => {
                const result = container.clone({ nodes: [] });

                ['ltr', 'rtl'].forEach(dir => {
                    result.nodes = result.nodes.concat(
                        addSelectors(container, className => {
                            return [
                                `[custom-dir='${dir}'] .${dir}${e(separator)}${className}`,
                                `[custom-dir='${dir}'].${dir}${e(separator)}${className}`,
                            ];
                        })
                    );
                });

                return result;
            });
      }),
  ],
};

function addSelectors(container, modifierFunction) {
	const rules = [];

	container.walkRules(rule => {
		const selectors = rule.selectors.map(selector => modifierFunction(selector.slice(1)));
		rules.push(rule.clone({ selectors }));
	});

	return rules;
}
