import { createStore } from "vuex";
import RequestService from "../services/RequestService";
import { documentEditor } from "./documentEditor";
import { mobileSign } from "./mobileSign";
import { auth } from "./authentication";
import { documents } from "./documents";
import { request } from "./request";

export default createStore({
  state: {
    level: null,
    error: null,
    loading: false,
  },
  mutations: {
    SET_ERROR(state, error) {
      console.log("#set error", error);

      window.parent.postMessage({
        type: 'ERROR'
      }, '*');

      state.error = error;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    /**
     * First call when starting signing app
     * requires requestId, recipientId and the hash from the query url
     *
     * returns security Level and branding
     * */
    async initiate({ commit, dispatch, rootState }) {
      const response = await RequestService.initiate(
        rootState.request.requestId,
        rootState.request.recipientId,
        { hash: rootState.auth.hash }
      );

      console.log("#initiate response", response);

      if (response.data) {
        /* if response contains error -> show error message */
        if (response.data.errors && response.data.message) {
          commit("SET_ERROR", response.data);
        }
        /* if response contains data -> set level and theming */
        if (response.data) {
          commit("request/setSecurityLevel", response.data.level, { root: true });
          commit("request/setBranding", response.data.branding);
          commit("auth/setQES", response.data.qes);
          commit("request/setLocalization", response.data.localization);
          commit("request/setSignatureSettings", response.data.settings);
        }
      }

      dispatch("debugVuex");
    },
    debugVuex({ state }) {
      console.log("#vuex", state);
    },
  },
  modules: {
    documentEditor: documentEditor,
    documents,
    mobileSign,
    auth,
    request
  },
});
