<template>
  <div class="mb-9 md:pr-32">
    <p v-if="theme.showSender" class="mb-0.5 font-medium rtl:text-right text-lg text-neutral-2 leading-6 ">
      {{ request.subject }}
    </p>

    <div
      v-if="request.message"
      ref="messageContainer"
      :class="showMore ? 'max-h-96' : 'max-h-18 line-clamp-3'"
      class="mb-1 overflow-hidden text-base transition-all duration-500 ease-in-out rtl:text-right text-neutral-2"
    >
      <span ref="messageText" class="whitespace-pre-wrap">
        {{ request.message }}
      </span>
    </div>

    <BaseButton
      v-if="showMore !== null"
      text-link
      :style="`color:${theme.primaryBgColor}`"
      class="mb-3"
      @click.prevent="toggleShowMore"
    >
      <div class="text-sm md:text-base">
        <span v-if="showMore">« {{ t("base.showLess") }}</span>
        <span v-else>» {{ t("base.showMore") }}</span>
      </div>
    </BaseButton>

    <div v-if="theme.showSender" class="pt-1 pb-3 text-xs border-b md:text-sm border-neutral-9 rtl:text-right">
      <span class="text-neutral-6">{{ t("base.messageFrom") }}</span>
      <span class="text-neutral-4">&nbsp;{{ request.sender.email }}</span>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useTheme from "../../composables/LayoutAndStyle/useTheme";

export default {
  props: {
    request: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n();
    const { theme } = useTheme();
    const showMore = ref(null);
    const messageContainer = ref(null);
    const messageText = ref(null);

    onMounted(() => {
      if (messageText.value?.offsetHeight > messageContainer.value?.clientHeight) {
        showMore.value = false;
      }
    });

    function toggleShowMore() {
      showMore.value = !showMore.value;
    }

    return {
      t,
      theme,
      showMore,
      toggleShowMore,
      messageContainer,
      messageText,
    };
  },
};
</script>
