<template>
  <FadeTransition>
    <div
      ref="panzoomOwner"
      class="relative bg-neutral-10 md:pt-14"
    >
      <Toolbar>
        <ToolbarContent
          @show-confirm="showConfirmSubmitModal = true"
          @showDeclineModal="showDeclineModal = true"
          @showLanguageModal="showLanguageModal = true"
        />
      </Toolbar>
      <div
        :class="isMobile ? 'overflow-hiddenXX' : ''"
        class="w-screen h-auto h-screenXX md:w-auto md:h-auto"
      >
        <div
          ref="zoomContainer"
        >
          <div class="relative px-4 py-4 mx-auto md:px-0 md:py-8 w-fit-content">
            <form>
              <div
                v-for="(page, pageIndex) in pages"
                :key="page.id"
                class="mb-6"
              >
                <Page :page="page">
                  <Field
                    v-for="field in page.fields"
                    :key="field.id"
                    :field="field"
                    :page="page"
                    :show-todo-hint="field.id === activeTodoHintId"
                  />
                </Page>
                <PageFooter
                  :document="document"
                  :current-page="pageIndex+1"
                />
              </div>
            </form>

            <ArrowHint />
          </div>
        </div>
      </div>
      <CookieDataPrivacyAlert />
    </div>
  </FadeTransition>

  <teleport to="body">
    <ConfirmSubmitModal
      v-if="showConfirmSubmitModal && !isBatch"
      @onCancel="showConfirmSubmitModal = false"
      @onSubmit="onSubmit"
    />

    <SelectQesTypeModal
      v-if="showConfirmSubmitModal && isBatch"
      @onCancel="showConfirmSubmitModal = false"
      @onSubmit="onSubmit"
      :selected-documents="[document]"
    />

    <ConfirmDeclineModal
      v-if="showDeclineModal"
      @onCancel="showDeclineModal = false"
      @onSubmit="onDecline"
    />

    <LanguageModal
      v-if="showLanguageModal"
      @close="showLanguageModal = false"
      @submit="showLanguageModal = false"
    />
  </teleport>
</template>

<script>
import {computed, onMounted, onBeforeUnmount, ref, provide} from "vue";
import { useStore } from "vuex";
import router from "../router";
import eventBus from "../eventbus";
import Page from "../components/DocumentEditor/Page";
import PageFooter from "../components/DocumentEditor/PageFooter";
import Field from "../components/DocumentEditor/Field";
import Toolbar from "../components/DocumentEditor/Toolbar";
import ArrowHint from "../components/DocumentEditor/ArrowHint";
import ToolbarContent from "../components/DocumentEditor/ToolbarContent";
import CookieDataPrivacyAlert from "../components/Legal/CookieDataPrivacyAlert";
import FadeTransition from "../components/Common/Transitions/FadeTransition";
import {sortFields} from "../composables/Document/useFieldLayout";
import store from "../store";
import useDocuments from "../composables/Request/useDocuments";
import useDeviceDetection from "../composables/LayoutAndStyle/useDeviceDetection";
import useScrollToCurrentField from "../composables/LayoutAndStyle/useScrollToCurrentField";
import {useI18n} from "vue-i18n";
import ConfirmSubmitModal from "../components/DocumentEditor/Modals/ConfirmSubmitModal";
import SelectQesTypeModal from "../components/DocumentOverview/SelectQesTypeModal.vue";
import ConfirmDeclineModal from "../components/DocumentEditor/Modals/ConfirmDeclineModal";
import LanguageModal from "../components/Common/Language/LanguageModal.vue";

export default {
  name: 'DocumentEditor',
  components: {
    ArrowHint,
    Page,
    PageFooter,
    Field,
    Toolbar,
    ToolbarContent,
    CookieDataPrivacyAlert,
    FadeTransition,
    ConfirmSubmitModal,
    SelectQesTypeModal,
    ConfirmDeclineModal,
    LanguageModal,
  },
  beforeRouteEnter(to, from, next) {
    if(!store.state.documents.currentDocument) {
      next({
        name: "SigningApp",
        params: {
          requestId: to.params.requestId,
          recipientId: to.params.recipientId,
        } })
    } else {
      next();
    }
  },
  setup() {
    const zoomContainer = ref(null);
    const panzoomOwner = ref(null);
    const store = useStore();
    const showConfirmSubmitModal = ref(false);
    const showDeclineModal = ref(false);
    const showLanguageModal = ref(false);
    const { isMobile } = useDeviceDetection();
    let zoomHandler = ref(undefined);
    const { t } = useI18n();

    const isBatch = computed(() => store.state.auth?.qes?.batch || false);

    const scrollToHandler = useScrollToCurrentField(store, eventBus, zoomHandler, isMobile);

    const document = computed(() => {
      return store.state.documents.currentDocument;
    });
    console.log("active document", document);

    const pages = Object.assign(
      document.value.pages.sort((a, b) => (a.page < b.page ? -1 : 1))
    );
    pages.forEach(page => {
      page.fields = document.value.fields
        .filter(field => field.page === page.page)
        .sort(sortFields);
    });


    const signDocumentState = computed(() => {
      return store.getters["documentEditor/getSignDocumentState"];
    });


    /*
     * Panzoom
     */
    // if (isMobile) {
    //   zoomHandler.value = useZoomHandler(
    //     zoomContainer,
    //     panzoomOwner,
    //     {isMobile},
    //     {
    //       interceptClickEvent: () => store.commit('documentEditor/setLastFieldIdTouched', undefined)
    //     }
    //   );
    // }


    function goBackOrRedirect(isDeclined = false) {
      let request = store.state.request.request;
      let redirectUrl = isDeclined ? request.redirect_error_url : request.redirect_success_url;

      let docsToSignLeft = useDocuments(
        store.state.request.recipientId,
        request,
      ).docsToSign(request.documents, true).length > 0;


      if (redirectUrl && !docsToSignLeft) {
        router.push({
          name: 'Redirect',
          query: {
            mode: isDeclined ? 'declined' : 'success',
            to: redirectUrl
          }
        })
      } else {
        window.parent.postMessage({
          type: isDeclined ? 'REQUEST_DECLINED' : 'REQUEST_COMPLETED'
        }, '*');

        router.back();
      }
    }

    /**
     * onDecline Request as a whole, not only the current document
     */
    async function onDecline(declineReason) {
      await store.dispatch('request/declineRequest', {
        document: document.value,
        reason: declineReason,
      });

      await store.dispatch('request/fetchRequest');
      goBackOrRedirect(true);
    }

    /**
     * onSubmit this current document
     */
    async function onSubmit(isQes) {
      if (! isQes) {
        await store.dispatch('documentEditor/submitDocument', document.value);
      }

      await store.dispatch('request/fetchRequest');
      goBackOrRedirect();
      store.commit("SET_LOADING", false);
    }

    /*
     * TODO: deprecated? do we want to use tooltips?
     */
    const activeTodoHintId = 0;

    function goBackInterceptor(e) {
      if (signDocumentState.value === 'start'){
        return void (0);
      } else {
        let confirmationMessage = t('document.abort.closeTabHint');
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;                            //Webkit, Safari, Chrome
      }
    }

    /**
     * Lifecycle Hooks
     */
    onMounted(() => {
      eventBus.emit("showBackgroundAnimation", false);
      zoomHandler.value && zoomHandler.value.mountMe();

      scrollToHandler.mountMe();

      window.addEventListener("beforeunload", goBackInterceptor);

    });

    onBeforeUnmount(() => {
      store.commit("documents/clearActiveDocument");
      eventBus.emit("showBackgroundAnimation", true);
      scrollToHandler.unmountMe();
      zoomHandler.value && zoomHandler.value.unmountMe();

      window.removeEventListener("beforeunload", goBackInterceptor);

    });

    const alertBoxOptions = {
      position: {
        lgScreen: "bottom",
        smScreen: "top",
      }
    };
    provide('alertBoxOptions', alertBoxOptions);

    return {
      document,
      pages,
      router,
      activeTodoHintId,
      showConfirmSubmitModal,
      onSubmit,
      showDeclineModal,
      showLanguageModal,
      isMobile,
      onDecline,
      zoomContainer,
      panzoomOwner,
      isBatch,
    };
  },
};
</script>
