<template>
  <TransitionRoot
    appear
    :show="true"
    as="template"
  >
    <Dialog
      as="div"
      :open="true"
      class="fixed inset-0 z-30"
      @close="$emit('onCancel')"
    >
      <div
        class="fixed inset-x-0 bottom-0 z-50 items-center justify-center block h-full max-h-full min-h-full p-0 px-4 py-4 pb-4 overflow-y-auto"
      >
        <TransitionChild
          as="div"
          enter="duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 transition-opacity bg-black bg-opacity-75"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <TransitionChild
          as="div"
          :class="{
              'h-full': !fullScreen,
              'ios-min-h-screen': fullScreen
            }"
          class="flex items-end justify-center md:items-center"
          enter="duration-300 ease-out"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="duration-200 ease-in"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <!-- Modal Inner -->
          <div
            class="inline-block w-full text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl overflow-initial sm:align-middle md:max-w-2xl md:m-0"
            :class="[
              customClass,
            ]"
          >
            <div class="px-3 pt-3 pb-2 md:pt-6 md:px-4 landscape:px-7">
              <div class="sm:flex sm:items-start">
                <!-- placeholder for icon -->
                <div
                  v-if="$slots.icon"
                  class="flex-shrink-0 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10 sm:mr-3"
                >
                  <slot name="icon" />
                </div>
                <div class="w-full mt-3 sm:mt-0">
                  <h3
                    v-if="$slots.title"
                    id="modal-title"
                    class="mb-3 text-xl font-medium leading-6 text-center md:mb-5 md:text-left text-neutral-2"
                  >
                    <DialogTitle>
                      <slot name="title" />
                    </DialogTitle>
                  </h3>
                  <div>
                    <p class="text-base text-neutral-1">
                      <DialogDescription>
                        <slot name="description" />
                      </DialogDescription>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="$slots.buttons"
              class="flex justify-end px-5 py-3 space-x-4 rounded-b-lg bg-neutral-10 sm:px-6"
            >
              <slot name="buttons" />
            </div>
            <!-- Headless UI requires a focusable element (https://github.com/tailwindlabs/headlessui/issues/265) -->
            <button
              v-else
              type="button"
              class="absolute opacity-0"
            />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  DialogDescription,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";

import { onMounted, onBeforeUnmount } from "vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    TransitionRoot,
    TransitionChild,
  },
  props: {
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    fullScreen: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["onCancel"],
  setup() {
    let scrollY = 0;

    onMounted(() => {
      scrollY = document.documentElement.scrollTop;
      document.documentElement.classList.add("overflow-hidden", "fixed");
      document.documentElement.style.top = `-${scrollY}px`;
    });

    onBeforeUnmount(() => {
      document.documentElement.classList.remove("overflow-hidden", "fixed");
      document.documentElement.style.top = '';
      document.documentElement.scrollTo(0, parseInt(scrollY || '0'));
    });
  }
};
</script>