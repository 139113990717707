<template>
  <button
    ref="button"
    class="inline-flex relative items-center font-medium tracking-wide whitespace-nowrap rounded-md border-2 shadow-sm appearance-none select-none focus:shadow-none shadow-1 disabled:cursor-default focus:outline-none rounded-default"
    :class="[
      small
        ? 'text-sm md:text-base'
        : 'text-base md:text-xl',
      {
        'px-2 py-1' : small && !textLink,
        'px-2 py-1 md:px-2.5' : !small && !textLink,
      },
      {
        'text-white': !(
          outlined ||
          secondary ||
          success ||
          warning ||
          textLink ||
          textLinkWhite
        ),
        'bg-transparent border-black text-black shadow-none hover:bg-neutral-1 hover:text-neutral-10 shadow-none': outlined,
        'opacity-60 shadow-none': $attrs.disabled,
        'bg-neutral-1 text-white border-neutral-1 hover:bg-black hover:border-black': secondary,
        'bg-green-8 border-green-8 text-green-1 hover:bg-green-7 hover:border-green-7': success,
        'bg-yellow-8 border-yellow-8 text-yellow-1 hover:bg-yellow-7 hover:border-yellow-7': warning,
        'bg-transparent text-neutral-2 shadow-none border-none hover:text-black': textLink,
        'bg-transparent text-neutral-10 shadow-none border-none hover:white': textLinkWhite,
      },
    ]"
    :style="
      !(outlined || secondary || (success && ! theme.hasCustomPrimaryColor) || warning || textLink || textLinkWhite)
        ? `background-color:${theme.primaryBgColor}; border-color:${theme.primaryBgColor}; color:#ffffff;`
        : null
    "
    @mouseover="isPrimary ? changeStyle(theme.primaryBgColorDark) : null"
    @mouseleave="isPrimary ? changeStyle(theme.primaryBgColor) : null"
    @click.prevent="onClick"
  >
    <Loader
      v-if="loading && !$slots.icon"
      :show-text="false"
    />

    <span class="flex justify-center items-center">
      <span
        v-if="dropDown"
        class="ml-1"
      >
        <slot />
      </span>

      <span
        v-if="$slots.icon"
        class="inline-flex items-center"
      >
        <slot
          name="icon"
        />
      </span>

      <span
        v-if="!dropDown"
        :class="$slots.icon ? 'ml-2' : ''"
      >
        <slot />
      </span>
    </span>
  </button>
</template>

<script>
import { ref, computed } from "vue";
import useTheme from "../../composables/LayoutAndStyle/useTheme";
import Loader from "../../components/Common/Loader";

export default {
  name: "BaseButton",
  components: {
    Loader
  },
  props: {
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textLink: {
      type: Boolean,
      default: false,
      required: false
    },
    textLinkWhite: {
      type: Boolean,
      default: false,
      required: false
    },
    dropDown: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  setup(props) {
    const { theme } = useTheme();
    const button = ref(null);

    const isPrimary = computed(() => {
      const { success, warning, textLink, textLinkWhite, outlined, secondary } = props;
      return !(success || warning || textLink || textLinkWhite || outlined || secondary);
    });

    function changeStyle(color) {
      if (button.value) {
        button.value.style.backgroundColor = color;
        button.value.style.borderColor = color;
      }
    }

    return {
      theme,
      button,
      changeStyle,
      isPrimary,
    };
  },
};
</script>

