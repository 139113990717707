import apiClient from "../api";

/**
 * QES Service Calls
 */
export default {

	/**
	 * Initiate QES
	 * @param sessionToken
	 * @return {Promise<AxiosResponse<T>>}
	 */
	initiate(qesToken) {
        // temp for testing
        //return new Promise((resolve) => setTimeout(resolve, 3000));

		return apiClient.post('/qes/initiate', qesToken);
	},

    /**
	 * Complete QES
	 * @param sessionToken
	 * @param otp
	 * @return {Promise<AxiosResponse<T>>}
	 */
	complete(qesToken, otp, requestId, documentId, fields) {
        // temp for testing
        //return new Promise((resolve) => setTimeout(resolve, 3000));

        return apiClient.post(`/requests/${requestId}/documents/${documentId}/sign-qes`, {
            ...qesToken,
            otp,
            fields,
        });
	},

    completeMultiple(qesToken, otp, requestId, documents) {
        return apiClient.post(`/requests/${requestId}/sign-multiple-qes`, {
            ...qesToken,
            otp,
            documents,
        });
	},

    /**
     * Save signature token
     * @param requestId
     * @param signatureToken
     * @return {Promise<AxiosResponse<T>>}
     */
    completeQES(requestId, signatureToken) {
        return apiClient.post(
            `/requests/${requestId}/qes`,
            signatureToken,
        );
    },
}
